import React from 'react';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Image from './image';

import './press-quotes.scss';
import { Trans } from 'react-i18next';

const pressQuotes = [
  {
    quote: `businessInsider.quote`,
    author: 'Business Insider',
    imagePath: 'third-party-logos/business-insider--black.png',
  },
  {
    quote: `forbes.quote`,
    author: 'Forbes',
    imagePath: 'third-party-logos/forbes--black.png',
  },
  {
    quote: `wwd.quote`,
    author: 'WWD',
    imagePath: 'third-party-logos/wwd--black.png',
  },
  {
    quote: `ilSole24Ore.quote`,
    author: 'Il Sole 24 Ore',
    imagePath: 'third-party-logos/il-sole-24-ore--black.png',
  },
  {
    quote: `ilCorriere.quote`,
    author: 'Il Corriere',
    imagePath: 'third-party-logos/corriere-della-sera--black.png',
  },
  {
    quote: `cyzoneCn.quote`,
    author: 'Cyzone.cn',
    imagePath: 'third-party-logos/cyzone-cn--black.png',
  },
];

class PressQuotes extends React.Component {
  state = {
    currentSlide: 0,
  };

  sliderSettings = {
    arrows: false,
    dots: false,
    beforeChange: (current, next) => {
      this.setState({ currentSlide: next });
    },
  };

  goToSlide = index => () => {
    this.slider.slickGoTo(index);
  };

  render() {
    return (
      <div className="st-c-press-quotes">
        <Slider
          {...this.sliderSettings}
          ref={c => (this.slider = c)}
          className="st-c-press-quotes__slider"
        >
          {pressQuotes.map((pressQuote, index) => (
            <blockquote key={index} className="st-c-press-quotes__quote">
              <p>
                <Trans i18nKey={`PressQuotes:${pressQuote.quote}`}></Trans>
              </p>
            </blockquote>
          ))}
        </Slider>
      </div>
    );
  }
}

export default PressQuotes;
